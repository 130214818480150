import React, { useEffect } from 'react'
import {
    PaginationState,
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    flexRender,
  } from '@tanstack/react-table'
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TableContainer, Box, Paper, Table, TableHead, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }

type TableType = { data: Array<any>, size: string|undefined, columns: Array<any>, pageCount: number, itemCount: number, onFetch: any }

  

export default ({ data, columns, size = 'medium', pageCount, itemCount, onFetch }: TableType) => {
    const theme = useTheme();
    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 10 })
    const pagination = React.useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize])

    const table = useReactTable({
        data: data ?? [],
        columns,
        pageCount: pageCount ?? -1,
        state: {
          pagination,
        },
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
      })

      function TablePaginationActions(props: TablePaginationActionsProps) {
        const { count, page, rowsPerPage, onPageChange } = props;
      
        const handleFirstPageButtonClick = (
          event: React.MouseEvent<HTMLButtonElement>,
        ) => {
          onPageChange(event, 0);
        };
      
        const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, page - 1);
        };
      
        const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, page + 1);
        };
      
        const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
      
        return (
          <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
              onClick={handleFirstPageButtonClick}
              disabled={page === 0}
              aria-label="first page"
            >
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
              onClick={handleBackButtonClick}
              disabled={page === 0}
              aria-label="previous page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
              onClick={handleNextButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="next page"
            >
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
              onClick={handleLastPageButtonClick}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="last page"
            >
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
          </Box>
        );
      }

    return (
        <Box>
            <TableContainer elevation={0} component={Paper}>
                <Table size={size === 'small' ? 'small' : 'medium'}>
                    <TableHead>
                        {
                            table.getHeaderGroups().map(headerGroup => (
                                <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                    <TableCell  key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                        <div style={{ fontWeight: 'bold' }}>
                                            {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                            )}
                                        </div>
                                        )}
                                    </TableCell>
                                    )
                                })}
                                </TableRow>
                            ))
                        }
                    </TableHead>
                    <TableBody>
                        {table.getRowModel().rows.map(row => {
                            return (
                            <TableRow key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                return (
                                    <TableCell key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                    </TableCell>
                                )
                                })}
                            </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={itemCount}
                rowsPerPage={table.getRowModel().rows.length}
                page={table.getState().pagination.pageIndex}
                onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
                    table.setPageIndex(newPage)
                    onFetch({ pageIndex: newPage, pageSize })
                }}
                onRowsPerPageChange={e => {
                    table.setPageSize(Number(e.target.value))
                    onFetch({ pageIndex, pageSize: Number(e.target.value) })
                }}
                ActionsComponent={TablePaginationActions}
            />
        </Box>
    )
}
