import { get as _get, find as _find, filter as _filter, uniq as _uniq } from 'lodash'
import { Grid, Box, Toolbar, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { LeapFrog } from "@uiball/loaders";
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAppDispatch } from 'hooks/useAppDispatch';
import palette from 'theme/palette';
import { useAppSelector } from 'hooks/useAppSelector';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { deleteDAOAction, loadDaoAction } from 'store/actions/dao';
import Table from 'components/Table'
import moment from 'moment'
import Button from 'components/Button';


type pageType  = { limit: number, skip: number }


const useStyles = makeStyles((theme: any) => ({
    root: {
        paddingBottom: 60
    },
    logoContainer: {
        width: '30px !important',
        height: '30px !important',
        minWidth: '30px !important',
        minHeight: '30px !important',
        background: `linear-gradient(180deg, #FBF4F2 0%, #EEF1F5 100%)`,
        boxShadow: `inset 1px 0px 4px rgba(27, 43, 65, 0.1)`,
        borderRadius: 10,
        transform: `rotate(45deg)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow : 'hidden'
      },
      image: {
        transform: 'rotate(-45deg)',
        width: '141%',
        maxWidth: '141% !important',
        height: '141%;',
        flexShrink: 0
      },
      title: {
        fontWeight: '600 !important',
        fontSize: '10px !important',
        lineHeight: 25,
        textAlign: 'center',
        letterSpacing: '-0.011em !important',
        color: `${palette.primary.main} !important`,
        transform: `rotate(-45deg)`,
      },
}));


export default () => {
    const classes = useStyles()
    const navigate = useNavigate()
    const { account, provider, chainId } = useWeb3React();
    const [showDeleteAlert, setShowDeleteAlert] = useState<any>(null)
    const dispatch = useAppDispatch()
    const { dao, daoLoading, deleteDaoloading } = useAppSelector((store:any) => store?.dao)

    const renderImage = (DAO: any) => {
        if(!DAO) return ""
        return (
            <Box className={classes.logoContainer}>
                { DAO?.image ? <img className={classes.image} src={_get(DAO, 'image')} /> :
                    DAO && <Typography variant='h6' className={classes.title}>{
                    
                        _get(DAO, 'name', '').length === 1
                        ?
                        _get(DAO, 'name', '')[0]?.charAt(0)?.toUpperCase()
                        :
                        _get(DAO, 'name', '')[0]?.charAt(0)?.toUpperCase() + _get(DAO, 'name', '')[_get(DAO, 'name', '')?.length - 1]?.charAt(0)?.toUpperCase()
                    
                    }</Typography>
                }
                </Box>
        )
    }

    const COLUMNS = [
        {
          accessorKey: 'name',
          header: () => <span>Name</span>,
          cell: ({ row }: any) => 
          <div style={{ minWidth: 180, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {
                renderImage(row?.original)
            }
            <div style={{ marginLeft: 12, fontWeight: 600 }}>{ _get(row, 'original.name', null) }</div>
          </div>, 
        },
        {
            header: 'Treasury content',
            cell: ({ row }: any) => 
                <div style={{ minWidth: 120 }}>
                    {
                        _get(row, 'original.safe.tokens', []).map((token:any) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div>{ _get(token, 'token.symbol', '') }</div>
                                    <div style={{ marginLeft: 4 }}>{ (parseFloat(_get(token, 'balance', 0)) / 10 ** (token?.token?.decimals || token?.token?.decimal)).toFixed(3) }</div>
                                </div>
                            )
                        })
                    }
                </div>, 
        },
        {
            header: 'Treasury Balance',
            cell: ({ row }: any) => 
                <div style={{ minWidth: 100 }}>{ _get(row, 'original.safe.balance', null) === null ? '-' : `$${_get(row, 'original.safe.balance', '0.00')}` }</div>, 
        },
        {
          header: 'Member count',
          cell: ({ row }: any) => 
             <div>{ _get(row, 'original.members', []).length }</div>, 
        },
        // {
        //     header: 'Transaction volume',
        //     cell: ({ row }: any) => {
        //        return <div></div>
        //     }

        // },
        {
            header: 'SBT Enabled',
            cell: ({ row }: any) => 
               <span style={{ minWidth: 120 }}>{ row?.original?.sbt ? 'Yes': 'No' }</span>, 
        },
        {
            header: 'SWEAT Points',
            cell: ({ row }: any) => 
               <span style={{ minWidth: 120 }}>{ row?.original?.sweatPoints ? 'Yes': 'No' }</span>, 
        },
        {
            header: 'No. of token-gated permissions',
            cell: ({ row }: any) => {
                const projects = _get(row, 'original.projects', [])
                const linkCount = projects.reduce((a:any, b:any) => {
                    return a + _filter(_get(b, 'links', []), l => l?.accessControl).length
                }, 0)
                const unlockedCount = projects.reduce((a:any, b:any) => {
                    return a + _get(b, 'links', []).reduce((s: any, x: any) => s + (_uniq(x?.unlocked)?.length) || 0,  0)
                }, 0)
                return (
                    <div style={{ minWidth: 150, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>links: {linkCount}</div>
                        <div style={{ marginLeft: 16 }}>unlocked: {unlockedCount}</div>
                    </div>
                )
            } 
        },
        {
            header: 'No. of workspaces',
            cell: ({ row }: any) => 
               <div style={{ minWidth: 100 }}>{ _get(row, 'original.projects', []).length }</div>, 
        },
        {
            header: 'No. of tasks',
            cell: ({ row }: any) => 
               <div style={{ minWidth: 100 }}>{ _get(row, 'original.tasks', []).length }</div>, 
        },
        {
            header: 'Created date',
            cell: ({ row }: any) => 
               <div style={{ minWidth: 150 }}>{ moment(_get(row, 'original.createdAt', null)).format('MMM DD YYYY hh:mm A') }</div>, 
        },
        {
            header: 'Actions',
            cell: ({ row }: any) => 
              <IconButton disabled={deleteDaoloading} onClick={() => setShowDeleteAlert(row?.original?.url)}>
                { deleteDaoloading === row?.original?._id ? <LeapFrog size={50} color="#C94B32" /> : <DeleteIcon/> }
              </IconButton>, 
        },
      ]

    const loadData = ({ limit = 10, skip = 0 }: pageType) => {
        dispatch(loadDaoAction({ limit, skip }))
    }

    const handleDelete = (url: any) => {
        setShowDeleteAlert(null)
        dispatch(deleteDAOAction(url))
    }
    
    useEffect(() => loadData({ limit: 10, skip: 0 }), [])

    return (
        <Grid sx={{ pt: 12 }} item mt={3} pb={6} sm={12}>
            <Box sx={{ flexGrow: 1 }}>
                <Toolbar
                style={{
                    borderRadius: 5,
                    padding: '20px 22px 15px',
                    background: '#FFF'
                }}
                >
                    <Typography variant="h6"
                    style={{
                        color: '#76808D',
                        fontSize: 22,
                        fontWeight: 400
                    }}
                    component="div">Organizations ({ dao?.itemCount })</Typography>
                </Toolbar>
            </Box>
            <Box mt={0.5}>
                {   !dao && daoLoading ?
                    <Box>
                        <Typography>Loading..</Typography>
                    </Box> : 
                    <Table
                        data={dao?.data}
                        size="medium"
                        columns={COLUMNS}
                        pageCount={dao?.totalPages}
                        itemCount={dao?.itemCount}
                        onFetch={({ pageIndex, pageSize }: any) => loadData({ limit: pageSize, skip: pageIndex * pageSize })}
                    />
                }
            </Box>
            <Dialog
                open={showDeleteAlert}
                onClose={() => setShowDeleteAlert(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-title">
                    {"Are you sure you want to delete?"}
                </DialogTitle>
                <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                <Button size="small" variant="outlined" onClick={() => setShowDeleteAlert(false)}>Cancel</Button>
                <Button size="small" variant="contained" onClick={() => handleDelete(showDeleteAlert)} autoFocus>
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}